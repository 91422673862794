import React from "react";
import "./Topinfo.css";
import { Card, Button, Jumbotron, Image } from "react-bootstrap";
import Banner from "../../Photos/Header.jpg";

export default function TopInfo() {
    return (
        <div className="container-flex">
            <Jumbotron style={{ backgroundImage: `url(${Banner})` }}>
                {/* <Image src={Banner} fluid /> */}
                <h1>Fire Extinguishers + Safety Inspections</h1>
                <p>Making it easy to keep your crew safe</p>
                <p>
                    <Button>
                        {" "}
                        <a href="tel:+1-801-466-6065">Call Now</a>
                    </Button>
                </p>
            </Jumbotron>

            <div className="cards">
                <div className="bottom-card col-4">
                    <p>On-site Services</p>
                </div>
                <div className="bottom-card col-4">
                    <p>Prompt Scheduling</p>
                </div>
                <div className="bottom-card col-4">
                    <p>Fewer Headaches</p>
                </div>
            </div>
            <br />
            <div className="line"></div>
        </div>
    );
}
