import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Nav from "./Componets/Nav/Nav";
import About from "./Componets/About/About";
import Contact from "./Componets/Contact/Contact";

function App() {
    return (
        <div className="App">
            <Nav />
            <About />
            <Contact />
        </div>
    );
}

export default App;
