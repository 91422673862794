import { Card, CardDeck, CardColumns, CardImg, CardGroup, Button } from "react-bootstrap";
import React from "react";
import "./Banner.css";

export default function banner() {
    return (
        <section id="about">
            <div className="container-banner">
                <h1 classname="banner-text-center">
                    Because a fire extinguisher is a little thing
                    <br /> ...until you need it.
                </h1>
                <br />
                <div className="line"></div>
            </div>
        </section>
    );
}
