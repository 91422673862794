import React from "react";
import "./Nav.css";
// import { NavLink } from "react-router-dom";
import { Nav, Navbar, Button } from "react-bootstrap";
import Logo from "../../Photos/logo.png";
import Contact from "../Contact/Contact";
import { Link } from "react-scroll";

export default function Navigation() {
    return (
        // <div className="container-nav container-flex ">
        <Navbar bg="white" variant="danger" expand="lg">
            <img src={Logo} />
            <Navbar.Brand className="mr-auto  company-name " href="#home">
                Fire & Safety Technologies
            </Navbar.Brand>
            {/* <div className="navlinks"> */}

            <Nav className="nav-links">
                {/* <Nav.Link
                    // as=
                    className=" d-none d-lg-block  nav-text smoothscroll"
                    to="/about"
                    exact
                > */}
                <Link
                    to="about"
                    className="d-none d-lg-block  nav-text"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                >
                    About
                </Link>
                {/* </Nav.Link> */}

                {/* <Link to=""> */}
                <Link
                    to="contact"
                    className="d-none d-lg-block  nav-text"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                >
                    Contact
                </Link>
                {/* <Nav.Link role="button" className="d-none d-lg-block  nav-text" to="/contact " exact>
                    Contact
                </Nav.Link> */}
                {/* </Link> */}
                <Button>
                    {" "}
                    <a href="tel:+1-801-466-6065">Call Now</a>
                </Button>
            </Nav>

            {/* </div> */}
        </Navbar>
        // </div>
    );
}
