import { Card, Container, Col, CardGroup, Button, Row } from "react-bootstrap";
import React from "react";
import "./CardGroup.css";
import Calander from "../../Photos/Calander.png";
import Fire from "../../Photos/Fire.png";
import Headache from "../../Photos/Headache.png";

export default function CardGroupMain() {
    return (
        <Container fluid="xl" className="container-fluid">
            <h3 className="title">Protect Your Site, Your People, and yourself</h3>

            <Row>
                <CardGroup>
                    <Col xl={1} />
                    <Col>
                        <Card>
                            <Card.Img variant="top" img src={Fire} />
                            <Card.Body>
                                <Card.Title>On-site Servicing</Card.Title>
                                <Card.Text>
                                    We come to you. All inspections, and service is done on-site so you never
                                    have to coordinate drop-off, pickup, or downtime.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" img src={Calander} />
                            <Card.Body>
                                <Card.Title>Prompt Scheduling</Card.Title>
                                <Card.Text>
                                    We can be out on a site in just days, making it easy to stay safe and
                                    compliant, correct issues, and avoid fines.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Img variant="top" img src={Headache} />
                            <Card.Body>
                                <Card.Title>Fewer Headaches</Card.Title>
                                <Card.Text>
                                    We make safety simple with on-call refills, automated annual inspections,
                                    on-site service, and easy scheduling.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={1} />
                </CardGroup>
            </Row>

            <div className="container-flex btn-card">
                <Button>
                    {" "}
                    <a href="tel:+1-801-466-6065">Call Now</a>
                </Button>
            </div>
            <br />
            <Row>
                <div className="line" />
            </Row>
        </Container>
    );
}
