import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import "./Description.css";

export default function Description() {
    return (
        <Container fluid="xl">
            <Row d-sm-none>
                <Col xl={1} />
                <Col xl={3} className="how-col">
                    <h2 className="how">How It Works</h2>
                </Col>
                <Col sm={12} lg={4} className="d-block d-lg-none">
                    <p>Call to discuss your needs and schedule a visit.</p>
                    <p>We come out when it’s convenient for you.</p>
                    <p>Work goes on without any interruptions or down time.</p>
                </Col>
                <Col sm={0} lg={4} className="d-none d-lg-block">
                    <ul>
                        <li>Call to discuss your needs and schedule a visit.</li>
                        <li>We come out when it’s convenient for you.</li>
                        <li>Work goes on without any interruptions or down time.</li>
                    </ul>
                </Col>
                <Col xl={1} />
            </Row>
            <br />
            <div className="line"></div>
        </Container>
    );
}
