import React from "react";
import emailjs from "emailjs-com";
import { Container, Form, Row, Col } from "react-bootstrap";
import { init } from "emailjs-com";
import "./Contact.css";
init("user_8t7KxN9jVHLWYRxoTosvy");

// gmail
// user_55G32qovlBZQDLSHTI9g7
export default function ContactUs() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm("service_gno0bcx", "template_sm6axfu", e.target, "user_55G32qovlBZQDLSHTI9g7").then(
            (result) => {
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
    }

    return (
        <section id="contact">
            <Container fluid="xl">
                <Row>
                    <Col xs={1}></Col>
                    <Col lg={4} sm={12} className="Call-us">
                        <h1>Want us to call you?</h1>
                        <p>
                            Call us at 801-466-6065 today, or submit your info and we’ll call you within 24
                            hours.
                        </p>
                    </Col>
                    <Col xs={2}></Col>
                    <Col lg={3} sm={12} className="email-form ">
                        <form className="contact-form" onSubmit={sendEmail}>
                            <label>Name</label>
                            <input className="contact-data" type="text" name="user_name" />
                            <label>Email</label>
                            <input className="contact-data" type="email" name="user_email" />
                            <label>Company</label>
                            <input className="contact-data" type="text" name="user_company" />
                            <label>Phone #</label>
                            <input className="contact-data" type="phone" name="contact_number" />
                            <input className="contact-data" type="submit" value="Send" />
                        </form>
                    </Col>
                    <Col xl={1} />
                </Row>
            </Container>
        </section>
    );
}
