import React from "react";
import "./About.css";

//  Importing componets for about section
import CardGroup from "../CardGroup/CardGroup.js";
import Topinfo from "../Topinfo/Topinfo";
import Description from "../Description/Description";
import Banner from "../Banner/Banner";
import Mission from "../Mission/Mission";
import Difference from "../Difference/Difference";

export default function About() {
    return (
        <div className="container-about">
            <Topinfo />
            <Banner />
            <Mission />
            <CardGroup />
            <Description />
            <Difference />
        </div>
    );
}
