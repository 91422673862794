import { Container, Row, Col, Image } from "react-bootstrap";
import React from "react";
import "./Difference.css";
import Side from "../../Photos/side.jpg";

export default function differnce() {
    return (
        // <section id="about">
        <Container className="difference-cont" fluid="xl">
            <Row>
                <Col xl={1} />
                <Col lg={4} sm={12}>
                    <h2 className="">The Fire & Safety Technologies Difference</h2>
                    <p>
                        It’s your job to keep people safe, but you’ve got a lot going on and no time for extra
                        work. We take the hassle out of fire extinguishers and safety inspections, so you can
                        focus on bigger issues while knowing your team is always prepared with the equipment
                        they need in the event of a fire.
                    </p>
                </Col>
                <Col sm={0} lg={2}></Col>
                <Col sm={12} lg={3} className="  side-img d-sm d-lg-block">
                    <Image src={Side} fluid />
                    <div>
                        <h6>Photograph by Andrea Leopardi </h6>
                    </div>
                </Col>

                <Col xl={1} />
            </Row>
            <br />
            <div className="line" />
        </Container>
        // </section>
    );
}
