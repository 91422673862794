import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import "./Mission.css";

export default function mission() {
    return (
        <Container fluid="xl">
            <Row d-sm-none>
                <Col xl={1} />
                <Col xl={3} className="mission-header ">
                    <h2 className="">
                        At Fire & Safety Technologies we make it simple to ensure you have the up-to-date
                        equipment you need for a safe site.
                    </h2>
                </Col>
                <Col sm={12} lg={4} className="Ul-list d-block d-lg-none">
                    <p className="bullet-mission">Licensed by the Utah State Fire Marshall</p>
                    <p className="bullet-mission">
                        Insured and bonded for all business types including active mining sites, airports, and
                        heavily restricted areas
                    </p>
                    <p className="bullet-mission">
                        Specialized in heavy construction and regulated industries
                    </p>
                    <p className="bullet-mission">Experienced with heavy equipment</p>
                </Col>

                <Col sm={0} lg={4} className="Ul-list d-none d-lg-block ">
                    <ul>
                        <li className="bullet-mission">Licensed by the Utah State Fire Marshall</li>
                        <li className="bullet-mission">
                            Insured and bonded for all business types including active mining sites, airports,{" "}
                            and heavily restricted areas
                        </li>
                        <li className="bullet-mission">
                            Specialized in heavy construction and regulated industries
                        </li>
                        <li className="bullet-mission">Experienced with heavy equipment</li>
                    </ul>
                </Col>
                <Col xl={1} />
            </Row>
            <br />
            <div className="line" />
        </Container>
    );
}
